import "./Home.css"

export default function Home() {
  return (
    
    <div className="page-container" id="home-page-container">

      <div className="page-image-container" id="home-page-image-container">
        <img id="home-logo" src="/images/logo.png"></img>
      </div>
      
      <div className="page-subtitle typing-effect" id="home-page-subtitle">Production Sound Mixer</div>
      <br></br>
      <div className="page-text">Greater Chicago</div>
      
    </div>
  );
}